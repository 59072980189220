.container {
  width: 100%;
  padding: 20px;
  height: 100%;
  background: white;
}

.greyBox {
  background: #F6F6F6;
  border-radius: 6px;
  padding: 12px;
}

.currentClubTitle {
  margin-top: 24px;
}

.statTitle {
  font-size: 13px;
  color: #2A3532;
  opacity: 0.4;
  margin-bottom: 2px;
}

.statValue {
  font-size: 16px;
  color: #2A3532;
}

.actions {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.15);
}

.currentClub {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #2A3532;
  justify-content: space-between;
  margin-bottom: 22px;
}

.currentClubInfo {
  width: 100%;
  position: relative;
  min-height: 55px !important;

  .clubBadge {
    width: 46px;
    height: 54px;
    position: absolute;
    left: 8px;
    top: -7px;
    object-fit: contain;

    .clubImg {
      position: absolute;
      width: 30px;
      height: 30px;
      left: calc(50% - 15px);
      top: calc(50% - 15px);
    }

    .clubImgHolder {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .teamIcon {
    min-width: 16px;
    height: 16px;
  }

  .teamInfo {
    margin-left: 37px;
    margin-top: 29px;

    .teamInfoValues {
      margin-left: 17px;
    }
  }

  .clubName {
    margin-left: 70px;
  }

  .value {
    font-weight: 500;
    font-size: 13px;
    color: #2A3532;
  }

  .valueLarge {
    font-size: 16px;
  }

  .title {
    font-size: 13px;
    color: #2A3532;
    opacity: 0.6;
  }
}

