.component {
  min-height: 100vh !important;
  //background: #F5F6FA url('/images/world-dots-original.png') center center no-repeat;
}

.content {
  width: 100%;
  max-width: 500px;
}

.contentPart {
  width: 100%;
}

@media (min-width: 1000px) {
  .contentPart {
    width: 50%;
  }
}

.title {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

@media (min-height: 700px) {
  .title {
    font-size: 36px;
    margin-top: 100px;
    margin-bottom: 20px;
  }
}

.container {
  width: calc(100% - 20px);
  //max-width: 600px;
  padding: 16px;
  margin-top: 20px;
  justify-content: space-between;
  min-height: 100px;
}

.subtitle {
  text-align: center;
  font-size: 16px;
  margin-left: 10px;
  align-self: flex-start;
  margin-top: 30px;
}

.form {
  max-width: 800px;
  display: flex;
  flex-direction: column;
}

.label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
}

.button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  //background-color: $color-primary;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  align-self: center;
}

.button:hover {
  color: #fff;
  cursor: pointer;
  //background-color: $color-primary;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.button[disabled] {
  opacity: 0.6;
}

.input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 0;
  //box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
  //rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  //border-radius: 4px;
  border-bottom: 1px solid grey;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
}

.input:focus {
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
}

.input::placeholder {
  color: #aab7c4;
}

.result,
.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.error {
  color: #e4584c;
}

.result {
  color: #666ee8;
}

.card {
  width: 100%;
  max-width: 300px;
  height: 120px;
  border-radius: 10px;
  //border: 1px solid #9b9b9b;
  margin-bottom: 10px;
  box-shadow: #bdbdbd 0 0 3px;
}

.cardHolder {
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
}

.iconCard {
  width: 50px;
  height: 50px;
}

.defaultPayment {
  position: absolute;
  bottom: 5px;
  right: 10px;
  background: #cecece;
  //color: $color-text;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.iconCardHolder {
  position: relative;
  width: 30%;
  border-right: 1px solid #bdbdbd;
}

.paymentDetails {
  width: 70%;
  margin-left: 15px;
  position: relative;
}

.paymentCardsHolder {
  width: 100%;
}

.paymentInfo {
  //margin-top: 10px;
  margin-bottom: 15px;
  //color: $color-text
}

.deleteCard {
  margin-left: 40px;
  color: red;
  cursor: pointer;
}

.setDefaultCard {
  margin-left: 40px;
  cursor: pointer;
  margin-bottom: 20px;
}

.invoicesHolder {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
}

.invoiceStatus {
  margin-left: auto;
  text-transform: capitalize;
}

.loaderHolder {
  height: 300px;
}

.addNewPayment {
  margin-top: 10px !important;
  width: fit-content !important;
}

.dateAndTime {
  font-size: 14px;
  //color: $color-text;
  opacity: 0.7;
  margin-top: 5px;
  margin-bottom: 10px;
}


.downloadIcon {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.priceText {
  font-weight: 500;
}

.invoices {
  width: 100%;
}

.outstandingBalanceHolder {
  margin-bottom: 10px;
  margin-top: 10px;
}

.text {
  font-size: 14px;
  //color: $color-text;
}

.balanceText {
  margin-left: auto;
  font-weight: 500;
  //color: $color-primary;
}

.couponInput {
  width: 100%;
  margin-bottom: 10px !important;
}

.subscriptionContent {
  min-width: 200px !important;
  text-align: right;
}

.currentPackageName {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.upgradeCTA {
  margin-left: auto;
  //color: $color-primary;
  cursor: pointer;
  margin-top: 5px;
}

.packageInfoHolder {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.packageHolder {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.packageTitle {
  font-size: 18px;
  font-weight: 500;
  align-self: center;
}

.packageInfo {
  font-size: 16px;
}

.packageCurrency {
  //color: $color-primary;
  font-weight: 500;
}

.autorenewMessage {
  font-size: 11px;
  margin-bottom: 10px;
}

.promoCodeError {
  font-size: 11px;
  margin-bottom: 5px;
  color: red;
}

.subscribeButton {
  margin-top: 15px;
}

.promoCodeInput {
  flex: 1
}

.promoCodeAddButton {
  margin-left: 20px !important;
  margin-bottom: 7px !important;
}

.promoCodeTitle {
  font-size: 12px;
}

.promoCodeCode {
  font-weight: bold;
  margin: 10px 0;
}

.promoCodeAmount {
  font-weight: bold;
}