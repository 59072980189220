.pitch {
  position: relative;
  height: 100%;
  width: 100%;
}

.pitchImage {
  position: absolute;
  width: 92%;
  height: 100%;
  top: 0;
  left: 4%;
  z-index: 1;
  object-fit: contain;
}

.section {
  position: absolute;
  width: 92%;
  height: 100%;
  left: 4%;
  top: 0;
  z-index: 2;
  cursor: pointer;

  .sectionValue {
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
  }
}

.sectionClickCatcher {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 5;
  cursor: pointer;
}

.position {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #FAFAFA;
  border: 1px solid rgba(102, 106, 124, 0.3);
  position: absolute;
  font-size: 12px;
  color: #2A3532;
  opacity: 0.8;
  z-index: 2;
}

.backgroundRectangle {
  background: #F6F6F6;
  border-radius: 9px;
  position: absolute;
  width: 100%;
  height: 90%;
  left: 0;
  top: 5%;
}

.editable {
  cursor: pointer;
}