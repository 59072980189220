.component {
  padding: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  box-sizing: border-box;
}

.logoHolder {
  width: 100px;
  height: 100px;
  position: relative;

  .logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .logoEdit {
    position: absolute;
    top: 0;
    right: -18px;
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
}


.name {
  margin-top: 10px;
  margin-bottom: 10px;

  p {
    font-size: 20px;
    //margin-left: 36px;
  }

  //img {
  //  width: 20px;
  //  height: 20px;
  //  margin-right: 10px;
  //}

  .nameEdit {
    margin-left: 20px;
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
}

.levels {
  font-weight: bold;
  text-align: center;
}

.uploadInputField {
  visibility: hidden;
  width: 1px;
  height: 1px;
}
