body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

/*
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
  display: block;
  max-width: 500px;
  padding: 10px 10px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px;
  border: 1px solid #D8D6DE;
  border-radius: 4px;
  background: white;
  box-sizing: border-box;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementFpx--focus {
//box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
//rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  border-color: #46d98a;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementFpx {
  padding: 0;
}

@page {
  size: A4;
  margin: 0;
  padding: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 0;
  }

  .hide-on-print {
    display: none !important;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
