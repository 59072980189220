.container {
    padding: 20px;
    max-height: 400px;
    overflow: auto;
}

.done {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 14px;
}

.thumbnail {
    width: calc(50% - 10px);
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin: 5px;
}

.thumbnailContainer {
    width: 100%;
}

.albumName {
    margin: 10px 25px;
    font-size: 20px;
}

.thumbnailWrapper, .addImageWrapper {
    width: calc(50% - 10px);
    max-width: 200px;
    margin: 5px;
    height: 100px;
    border-radius: 9px;
    overflow: hidden;
    box-shadow: 0 0 11px 2px #dfdfdf;
    background: white;
    position: relative;
}

.thumbnailImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 3px;
}

.uploadInputField {
    visibility: hidden;
    width: 1px;
    height: 1px;
}

.uploadInputArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
}

.deleteTeamIcon {
    position: absolute;
    top: 4px;
    right: 5px;
    width: 11px;
    height: 12px;
    object-fit: contain;
    cursor: pointer;
    padding: 4px;
    background: #ffffffd4;
    border-radius: 50%;

    &:hover {
        background: white;
    }
}
