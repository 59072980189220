.container {
    padding: 20px;
}

.image {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}

.text {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0 20px 24px 20px;
    color: rgba(0, 0, 0, 0.87);
}

.done {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 14px;
}

.input {
    width: 100%;
}

.characterCounter {
    font-size: 12px;
    color: #747474;
    margin-left: auto;
}
