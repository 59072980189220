.container {
    padding: 20px;
}

.title {
    align-self: center;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
}

.exportOptions {
    margin-bottom: 20px !important;
}

.exportWarning {
    margin-bottom: 30px;
    font-size: 12px;
    color: #b3b3b3;
}

.progressText {
    margin-bottom: 20px;
}

.progressIndicator {
    margin: 20px;
    align-self: center;
}

.ctaButton {
    align-self: center;
}