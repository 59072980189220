
.component {
  min-height: 100vh !important;
}

.title {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

@media (min-height: 700px) {
  .title {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

.container {
  width: calc(100% - 20px);
  max-width: 600px;
  padding: 16px;
  margin-top: 20px;
  justify-content: space-between;
  //min-height: 100px;

  .containerTitle {
    width: 100%;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #000000;

  }
}

.flag {
  cursor: pointer;
  margin-left: 20px;
  width: 36px !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  background-size: cover;
}

.subscriptionContent {
  min-width: 200px !important;
  text-align: right;

  .upgradeCTA {
    //color: $color-primary;
    cursor: pointer;
    margin-top: 5px;
  }
}

.nameInputContainer {
  flex-direction: column !important;
  width: 100%;
}

@media (min-width: 1000px) {
  .nameInputContainer {
    flex-direction: row !important;
  }
}

.input {
  width: 100%;
}

.changeEmailMessage {
  font-size: 11px;
  align-self: flex-start;
  color: #9e9b9b;
  margin-bottom: 30px;
}

.updateInfoError {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 11px;
}
