.component {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
}

.sectionTitle {
}

.albums {
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;

  .album {
    width: calc(50% - 10px);
    margin-bottom: 10px;
    cursor: pointer;

    .albumImageHolder {
      height: 80px;
      width: 100%;
      border-radius: 9px;
      background: #f5f5f5;
      overflow: hidden;

      .albumImage {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }

      .albumNoImage {
        width: 60px;
        height: 50px;
        object-fit: contain;
      }

      &:hover {
      }
    }
  }
}

.editHolder {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #F5F5F5;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
}
