.component {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  min-height: 250px;
}

.sectionTitle {
}

.teams {
  margin: 10px -5px;
  width: calc(100% + 20px);
  justify-content: flex-start;

  .team {
    width: 80px;
    height: 80px;
    border-radius: 9px;
    margin: 5px;
    background: #F5F5F5;
    position: relative;

    .deleteTeamIcon {
      position: absolute;
      top: 5px;
      right: 7px;
      width: 7px;
      height: 12px;
      object-fit: contain;
      cursor: pointer;
    }

    .teamGenderHolder {
      position: relative;
      width: 40px;
      height: 40px;
    }

    .teamGenderHex {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .teamGender {
      width: 17px;
      height: 17px;
      z-index: 10;
      object-fit: contain;
    }

    .teamName {
      font-size: 12px;
      color: #292D3C;
    }

    .leagueLevel {
      font-size: 10px;
      color: rgba(41, 45, 60, 0.51);
      margin-top: 4px;
    }
  }
}

.editHolder {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #F5F5F5;
  box-shadow: 0 4px 34px rgba(0, 0, 0, 0.15);
}

.showAllTeams {
  align-self: center;
  cursor: pointer;
}
