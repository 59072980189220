.container {
  padding: 16px;
}

.title {
  font-size: 20px;
}

.buttons {
  width: 100%;
  margin: 20px 0px;

  .shareButton {
    margin: 5px;
    cursor: pointer;
  }
}
