.component {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  min-height: 250px;
}

.sectionTitle {
  min-height: fit-content !important;
}

.content {
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  color: #585858;
  cursor: pointer;
  font-size: 12px;
}

.readMore {
  margin-left: 10px;
}

.editHolder {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #F5F5F5;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
}
