.container {
  padding: 20px;
  overflow: auto;
}

.requestOwner {
  width: 100%;
}

.requestName {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.requestInfo {
  font-size: 14px;
  margin-bottom: 3px;
  min-height: fit-content !important;
  min-height: -moz-max-content !important;

  span {
    min-width: 100px;
  }
}

.done {
  font-size: 20px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 14px;
}

.input {
  width: 100%;
}

.clubsHolder {
  width: 100%;
  padding: 20px;
  border-radius: 10px;;

  .clubBadge {
    width: 150px;
    height: 120px;
    margin: 4px;
    padding: 10px 4px 8px 4px;
    cursor: pointer;
    border: 2px solid transparent;

    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02);
    border-radius: 9px;
  }
}

.resolutionSectionTitle {

}

.resolveContainer {
  width: 100%;
  justify-content: space-between;
}

.resolveWithExistingContainer {
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px 20px 10px 20px;
  background: #f6f6f6;
  border-radius: 10px;
}

.selectLocationRow {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  .selectLocationLabel {
    width: 120px;
  }

  .selectLocationValue {
    font-weight: bold;
    cursor: pointer;
  }
}

.searchField {
  width: 100%;
}

.pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
  margin-bottom: 10px;
}

.pagination li {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  outline: none;
}

.paginationActive {
  font-weight: bold;
}

.paginationSubContainer {
  margin-left: 15px;
  margin-right: 15px;
}

.logoHolder {
  width: 100px;
  height: 100px;
  position: relative;

  .logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .logoEdit {
    position: absolute;
    top: 0;
    right: -18px;
    width: 18px;
    height: 18px;
    object-fit: contain;
    cursor: pointer;
  }
}

.uploadInputField {
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.teams {
  width: 100%;

  .team {
    width: 100%;
    justify-content: space-between;
  }

  .delete, .add {
    cursor: pointer;
    color: red;
  }

}
