.component {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
}

.sectionTitle {

}

.location {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.map {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.editHolder {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #F5F5F5;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
}
