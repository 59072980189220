.container {
  width: 100%;
  padding: 20px;
  height: 100%;
  background: white;
}

.sectionTitle {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #2A3532;
  justify-content: space-between;
  margin-bottom: 22px;
}

.link {
  font-size: 12px;
  color: #e26627;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: #e26627;
  }

  .logo {
    width: 70px;
    min-width: 70px;
    height: 40px;
    object-fit: contain;
    object-position: left;
    margin-right: 20px;
  }
}

.linksContainer {
  width: 100%;
}

.linkValue {
  width: calc(100% - 90px);
  line-break: anywhere;
}