
.alertContainer {
  padding: 20px;
  position: relative;
}

.alertText {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 20px 20px 20px 20px;
  //opacity: 0.87;
}

.contactInfoContainer {
  //background: rgba(244, 245, 249, 0.51);
  //border-radius: 8px;
  width: 100%;
  padding: 16px;
}

.line {
  width: 80%;
  height: 0.5px;
  margin-bottom: 20px;
}

.section {
  color: #46d98a;
  font-size: 16px;
  margin-bottom: 6px;
}

.secondSection {
  margin-top: 20px;
}

.link {
  font-size: 14px;
  color: #000;
  margin-bottom: 4px;
}

.linkSubtitle {
  font-size: 14px;
  color: #0000008a;
}

.buttonHolder {
  width: 100%;
  justify-content: space-between;

  &.buttonHolderLoaded {
    justify-content: center;
  }
}

.button {
  width: 100px;
}

.text {
  font-size: 14px;
  margin-bottom: 10px;
}

.closeIcon {
  width: 20px;
  height: 20px;
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
  margin-top: 5px;
}

.phoneIcon {
  width: 30px;
  height: 30px;
  margin-right: 50px;
}

.mailIcon {
  width: 30px;
  height: 30px;
  margin-right: 50px;
}

.contactHolder {
  width: 100%;
  margin-bottom: 15px;
}
