.container {
    padding: 20px;
}

.image {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}

.text {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0 20px 24px 20px;
    color: rgba(0, 0, 0, 0.87);
}

.done {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 14px;
}

.formControl {
    min-width: 120px !important;
}

.alertContainer {
    padding: 20px;
}

.alertImg {
    width: 50px;
    min-height: 50px;
    margin-top: 5px;
}

.alertText {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 20px 20px 20px 20px;
    color: rgba(0, 0, 0, 0.87);
}

.contactInfoContainer {
    background: rgba(244, 245, 249, 0.51);
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    margin-bottom: 25px;
}

.section {
    color: #46d98a;
    font-size: 16px;
    margin-bottom: 6px;
}

.secondSection {
    margin-top: 20px;
}

.link {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
}

.linkSubtitle {
    font-size: 14px;
    color: #0000008a;
}
