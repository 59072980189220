
.container {
  padding: 16px;
}

.title {
  font-size: 24px;
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
}

.searchField {
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 16px !important;
  min-height: fit-content !important;
}

.dialog {

}

.languages {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  .language {
    width: 100%;
    min-height: 20px !important;
    margin: 8px 0;
    font-size: 14px;
    cursor: pointer;

    //white-space: nowrap;

    .flag {
      width: 16px;
      height: 16px;
      min-width: 16px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
}
