.container {
  width: 100%;
  padding: 20px;
  height: 100%;
  background: white;
}

.sectionTitle {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #2A3532;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-top: 24px;
}

.pastClubs {
  font-weight: bold;
  margin: 10px;
}

.club {
  width: 100%;
  background: #F6F6F6;
  border-radius: 6px;
  padding: 8px 16px;
  margin-bottom: 15px;


  .clubBadge {
    height: 54px;
    width: 46px;
    margin-right: 14px;
    position: relative;

    .clubImg {
      position: absolute;
      width: 30px;
      height: 30px;
      left: calc(50% - 15px);
      top: calc(50% - 15px);
    }

    .clubImgHolder {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .clubInfo {
    flex-grow: 1 !important;

    .clubName {
      font-weight: 500;
      font-size: 16px;
      color: #2A3532;
      margin-bottom: 2px;
    }

    .teamName {
      font-size: 13px;
      color: rgba(42, 53, 50, 0.6);
    }
  }

  .seasons {
    font-size: 12px;
    line-height: 16px;
    color: rgba(42, 53, 50, 0.6);
  }
}
