
.alertContainer {
  padding: 20px;
}

.alertText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 10px 10px 40px 10px;
  color: rgba(0, 0, 0, 0.87);
  white-space: pre-wrap;
  overflow: auto;
}

.buttonContainer {
  width: 100%;
}
