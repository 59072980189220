.container {
  width: 100%;
  padding: 0 20px;
  height: 100%;
  position: relative;
  background: white;
  justify-content: flex-start;
  flex-direction: column !important;
}

@media (min-width: 1100px) {
  .container {
    justify-content: flex-start;
    flex-direction: row !important;
  }
}

.hexagonThinHolder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  .hexagonThinLeft {
    position: absolute;
    width: 199px;
    height: 225px;
    left: calc(50% - 199px/2 - 79.5px);
    top: -67px;
  }

  .hexagonThinRight {
    position: absolute;
    width: 199px;
    height: 225px;
    right: -30%;
    top: -104px;
  }
}



.nameFlagSection {
  margin-top: 16px;
  font-size: 20px;
  text-align: center;
  color: #2A3532;

  .flag {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.info {
  margin: 16px 0;
  font-size: 14px;
  color: #2A3532;
  justify-content: center;

  > div {
    margin-right: 5px;
  }
}

.mainDetailsSection {
  .item {
    background: linear-gradient(180deg, #F4F4F4 13.12%, #F8F8F8 99.42%);
    border-radius: 27px;
    padding:8px;
    margin: 4px 10px 4px 0;

    .icon {
      width: 14px;
      height: 14px;
    }

    .text {
      font-size: 14px;
      color: #2A3532;
      margin-left: 6px;
    }
  }
}

.hexagon {
  margin-top: -80px;
  position: relative;
  width: 120px;
  height: 135px;
  align-self: center;

  .hexagonBackground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .profilePic {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
    left: calc(50% - 45px);
    top: calc(50% - 45px);
    position: absolute;
  }
}

.cardInfoHolder {
  justify-content: center;

  .shareIcon {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    margin-right: 20px;
    z-index: 1;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.adminActionIcon {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  object-fit: contain;
  cursor: pointer;
  z-index: 1;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

@media (min-width: 1100px) {
  .hexagon {
    margin-top: -40px;
    width: 190px;
    height: 197px;
    align-self: unset;
    margin-right: 45px;


    .profilePic {
      width: 96px;
      height: 96px;
      left: calc(50% - 48px);
      top: calc(50% - 48px);
    }
  }

  .info {
    justify-content: flex-start;
  }

  .cardInfoHolder {
    justify-content: flex-start;
  }
}
