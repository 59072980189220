.component {
  min-height: 100vh !important;
}

.content {
  //width: calc(100% - 40px);
  //max-width: 1040px;
  //margin: 30px 20px 30px 20px;
}

.header {
  width: 100%;
}

.location {
  width: 100%;
}

.albums {
  width: 100%;
}

.about {
  width: 100%;
}

.teams {
  width: 100%;
}

@media (min-width: 500px) {
  .header {
    width: 300px;
    height: 300px;
  }

  .location {
    width: 300px;
    height: 300px;
  }

  .albums {
    width: 290px;
    height: 300px;
  }

  .about {
    width: 600px;
    height: 300px;
  }

  .teams {
    width: 290px;
    height: 300px;
  }
}

.adminButton {
  margin: 5px !important;
}

.publicCtaHolder {
  margin-top: 20px;

  .publicCta {
    margin-top: 10px !important;
  }
}

@media (min-width: 700px) {
  .publicCtaHolder {
    display: none !important;
  }
}


.bottomCtaHolder {
  padding-bottom: 100px;
}

.appStoreLink {
  cursor: pointer;
  width: 46%;
  margin-bottom: 10px;
  max-width: 300px;
  margin-left: 2%;
  margin-right: 2%;

  img {
    width: 100%;
    height: 100%;
  }
}

.appStoreTitle {
  text-align: center;
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .appStoreLink {
    width: 40%;
    margin-right: 5%;
    margin-left: 5%;
  }

  .appStoreTitle {
    margin-top: 20px;
  }
}