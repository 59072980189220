.container {
  width: 100%;
  padding: 20px;
  height: 100%;
  background: white;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}

.statsFullWidth, .statsHalfWidthRow {
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
}

.statsHalfWidthRow {
  justify-content: space-between;
}

.statsHalfWidth {
  width: calc(50% - 2px);
  height: 50px;
  margin-bottom: 5px;
}

.currentStats {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #2A3532;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-top: 24px;
}

.statTitle {
  font-size: 13px;
  color: #2A3532;
  opacity: 0.4;
  margin-bottom: 2px;
}

.actions {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.15);
}

.footOptions {
  justify-content: space-between;
}

.backgroundBox {
  width: 100px;
  height: 70px;
  margin-right: 10px;

  img {
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    color: #333333;
    opacity: 0.25;
  }
}

.greyBox {
  background: #F6F6F6;
  border-radius: 6px;
  padding: 12px;

  p {
    opacity: 1;
  }
}

