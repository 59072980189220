.container {
  width: 100%;
}

.profileWrapper {
  background: #FFFFFF;
  box-shadow: 0 24px 80px rgba(76, 96, 114, 0.08);
  border-radius: 10px;
  margin: 50px 10px 80px 10px;
  max-width: 600px;
}

.profileContainer,
.statsContainer,
.positionContainer,
.skillContainer,
.pastClubsContainer {
  width: 100%;
}

.profileContainer {
  flex-direction: column !important;
  align-items: center;

  .profileContainerRelevanceActions {
    min-width: fit-content !important;

    .profileContainerActions {
      justify-content: center;
    }
  }
}

.profileActionButton {
  height: fit-content;
  width: fit-content;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-top: 25px !important;
}

.columnContainer {
  width: 100%;
}

.statsContainer {
  margin-top: 1px;
}

.positionContainer {
  background: white;
  padding: 20px;

  .positions {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #2A3532;
    justify-content: space-between;

    .actions {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.15);
    }
  }
}

.position {
  font-weight: bold;
  align-self: flex-start;
  margin-left: 15px;
  margin-top: 15px;
}

.pitch {
  margin-top: 22px;
  width: 320px;
  height: 350px;
}

.additionalInfoContainer {
  margin: 20px;
  height: 250px;
  width: 500px;
  border: 1px solid lightgrey;
}

@media (min-width: 1100px) {
  .profileWrapper {
    margin: 20px 0 100px 0;
    max-width: unset;
  }

  .profileContainer {
    width: 1110px;
    flex-direction: row !important;
    align-items: unset;
  }

  .statsContainer,
  .positionContainer,
  .skillContainer,
  .pastClubsContainer {
    width: 350px;
    border-radius: 9px;
    margin: 10px;
    overflow: hidden;
  }

  .columnContainer {
    width: 350px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .profileActionButton {
    height: fit-content;
    width: fit-content;
    margin-left: 0 !important;
    margin-right: 25px !important;
    margin-top: 25px !important;
  }
}

.publicCtaHolder {
  margin-top: 20px;

  .publicCta {
    margin-top: 10px !important;
  }
}

@media (min-width: 700px) {
  .publicCtaHolder {
    display: none !important;
  }
}

.appStoreLink {
  cursor: pointer;
  width: 46%;
  margin-bottom: 10px;
  max-width: 300px;
  margin-left: 2%;
  margin-right: 2%;

  img {
    width: 100%;
    height: 100%;
  }
}

.appStoreTitle {
  text-align: center;
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .appStoreLink {
    width: 40%;
    margin-right: 10%;
    margin-left: 0;
  }

  .appStoreTitle {
    margin-top: 20px;
  }
}

.dataLabel {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.29);
  margin-right: 10px;
  min-width: 130px;
}

.info {
  margin: 5px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}

.adminArea {
  padding-bottom: 20px;
  background: #fdfdfd;
  border: 1px solid #217748;
  border-radius: 10px;
  margin: 20px 0;
  width: 100%;
  max-width: 1100px;

  .adminAreaTitle {
    align-self: center;
    font-weight: bold;
    margin: 10px 0;
    color: #217748;
  }

  .adminActionsRow {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;

    .adminActionsRowLabel {
      margin-right: 20px;
      min-width: 150px;
    }

    .adminActionButton {
      margin-right: 10px;
    }

    .adminActionText {
      margin-right: 10px;
    }
  }
}

.circularProgress {
  padding-bottom: 20px;
  margin: 20px 0;
  width: 300px;
}