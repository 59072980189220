.container {
    padding: 20px;
}

.image {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}

.text {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0 20px 24px 20px;
    color: rgba(0, 0, 0, 0.87);
}

.done {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 14px;
}

.formControl {
    min-width: 120px !important;

}

.thumbnail {
    width: calc(50% - 10px);
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin: 5px;
}

.thumbnailContainer {
    width: calc(100% - 10px);
    margin: 5px;

}

.albumName {
    width: calc(50% - 10px);
}

.thumbnailWrapper {
    width: calc(50% - 10px);
    margin: 5px;

    .albumImageHolder {
        width: 100%;
        height: 100px;
        object-fit: cover;
        border-radius: 9px;
        margin-bottom: 3px;
        cursor: pointer;
        box-sizing: border-box;
        overflow: hidden;

        &:hover {
        }

        .albumImage {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        .albumNoImage {
            width: 60px;
            height: 50px;
            object-fit: contain;
        }
    }
}