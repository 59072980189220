.container {
  width: 100%;
  padding: 20px;
  height: 100%;
  background: white;
}

.sectionTitle {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #2A3532;
  justify-content: space-between;
  margin-bottom: 22px;
}

.noHighlights {
  width: 100%;
  background: #F6F6F6;
  border-radius: 6px;
  padding: 8px 16px;
  margin-bottom: 15px;
  height: 60px;
}

.videosContainer {
  width: calc(100% + 10px);
  margin-left: -5px;
  //background: rgba(196, 196, 196, 0.20);

  .thumbnailHolder {
    position: relative;
    width: calc(50% - 10px);
    height: 100px;
    border-radius: 6px;
    margin: 5px;
    overflow: hidden;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  .playVideo {
    width: 24px;
    height: 24px;
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
}
