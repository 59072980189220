.container {
    position: fixed;
    z-index: 5000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: black;
    color: rgba(255, 255, 255, 0.76);

    .innerHolder {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.navigationLeft, .navigationRight {
    width: 60px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 100px;
    height: calc(100% - 200px);
    z-index: 5001;

    &:hover {
        //background: rgba(255, 255, 255, 0.12);
        background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.16), transparent);
    }
}

.navigationLeft {
    left: 0;
}

.navigationRight {
    right: 0;
}

.navigationArrowPrev, .navigationArrowNext {
    font-size: 40px;
}

.navigationArrowPrev {
    margin-right: 25px;
}

.navigationArrowNext {
    margin-left: 25px;
}

.close {
    z-index: 5001;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    cursor: pointer;
}

.contentHolder {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .content {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
        user-select: none;
    }
}
