.component {
    width: 100%;
    height: 100%;
}

.logo {
    margin-top: 10%;
    border-radius: 9px;
    width: 100%;
    height: 45%;
    min-height: 45%;
    object-fit: contain;
    margin-bottom: 10%;
    //border: 2px solid rgba(0, 0, 0, 0.09);
}

.clubName {
    text-align: center;
    font-size: 13px;
    color: #292D3C;
    word-break: break-word;
}

.clubLocation {
    margin-top: 3px;
    height: 40px;
    text-align: center;
    font-size: 10px;
    color: rgba(41, 45, 60, 0.25);
    overflow: hidden;
}
