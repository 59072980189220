.component {
  min-height: 100vh !important;
  //background: #F5F6FA url('/images/world-dots-original.png') center center no-repeat;
  padding-top: 20px;
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  height: 50vh;
  margin: 80px 30px 30px;
  background: transparent;

  p {
    margin: 0;
  }

  .createEmpty {
    margin-top: 30px !important;
  }
}

.opportunityContainer {
  width: 100%;
  max-width: 1200px;
  margin: 10px 30px 30px;
}

.manageTitle {
  width: calc(100% - 20px);
  height: fit-content;
  max-width: 600px;
  font-size: 16px;;
  flex-direction: column !important;
  margin-top: 20px;

  .manageTitleText {
    order: 2;
    margin-top: 20px;
    align-self: flex-start;
  }

  .createTop {
    order: 1;
  }

  .opportunityCounter {
    font-size: 12px;
    color: grey;
    text-align: center;
    margin-top: 10px;
  }
}


@media (min-width: 600px) {
  .manageTitle {
    flex-direction: row !important;
    justify-content: space-between !important;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    margin-top: 40px;

    .manageTitleText {
      order: 1;
      margin-top: 0;
    }

    .createTop {
      margin-top: 0 !important;
      order: 2;
    }

  }
}

@media (min-width: 1200px) {
  .manageTitle {
    max-width: 1200px;
  }

  .opportunityContainer {
    justify-content: flex-start !important;
  }
}