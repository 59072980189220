@import url('https://fonts.googleapis.com/css?family=Nunito');

$default-height: 95%;
$default-width: 50%;
$level2-height: $default-height - 8%;
$level2-width: $default-width - 8%;
$level1-height: $default-height - 2%;
$level1-width: $default-width - 2%;
$level0-height: $default-height + 5%;
$level0-width: $default-width + 5%;
$level-2-left: 15%;
$level-1-left: 30%;
$level0-left: 50%;
$level1-left: 70%;
$level2-left: 85%;
//$level-2-bs: 0px 10px 30px rgba(65, 196, 126, 0.45);
//$level-1-bs: 0px 10px 30px rgba(65, 196, 126, 0.45);
//$level2-bs: 0px 10px 30px rgba(65, 196, 126, 0.45);
//$level1-bs: 0px 10px 30px rgba(65, 196, 126, 0.45);
//$level0-bs: 0px 10px 30px rgba(65, 196, 126, 0.45);

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
}

.item {
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //height: fit-content;
  //transform: translate(-50%, -50%);
  //transition: box-shadow 1s, height 250ms, width 250ms, scale 1s, left 1s, margin-top 1s, line-height 250ms, background-color 1s;
  //transition: all 1s;
}

.hidden {
  opacity: 0;
}

.levelMinus2 {
  z-index: 10;
  transform: matrix(0.8, 0, 0, 0.8, -350, 0);
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
  visibility: visible;
}

.levelMinus1 {
  z-index: 20;
  transform: matrix(0.9, 0, 0, 0.9, -275, 0);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.0s;
  visibility: visible;
}

.level0 {
  z-index: 30;
  visibility: visible;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition: transform 0.75s cubic-bezier(0.86, 0, 0.07, 1) 0.5s;
}

.level1 {
  z-index: 20;
  visibility: visible;
  transform: matrix(0.9, 0, 0, 0.9, 275, 0);
  transition: transform 0.5s ease 0s, transform-origin 0.5s ease 0s;
}

.level2 {
  opacity: 1;
  z-index: 10;
  visibility: visible;
  transform: matrix(0.8, 0, 0, 0.8, 350, 0);
  transition: transform 0.5s ease 0s, transform-origin 0.5s ease 0s;
}


